import PbDropdown from "@/components/input/pbDropdown/pbDropdown";
import ModalInputWrapper from "@/components/util/modalInputWrapper";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import {
  MEDIA_TYPE_AUDIO,
  MEDIA_TYPE_IMG,
  MEDIA_TYPE_VIDEO,
  MEDIA_TYPE_YOUTUBE,
} from "@/utils/constants";
import { useState } from "react";

export type MediaSelectorOption =
  | typeof MEDIA_TYPE_IMG
  | typeof MEDIA_TYPE_YOUTUBE
  | typeof MEDIA_TYPE_VIDEO
  | typeof MEDIA_TYPE_AUDIO;

interface CmsMediaSelectorDropdownProps {
  defaultValue: MediaSelectorOption;
  mediaOptions?: Array<MediaSelectorOption>;
  onChange: (mediaType: MediaSelectorOption) => void;
}

export default function CmsMediaSelectorDropdown(
  props: CmsMediaSelectorDropdownProps
) {
  const tCms = useCmsTranslation();
  const [selectedMediaType, setSelectedMediaType] = useState(
    props.defaultValue
  );
  const defaultMediaOptions: MediaSelectorOption[] = props.mediaOptions ?? [
    MEDIA_TYPE_IMG,
    MEDIA_TYPE_YOUTUBE,
    MEDIA_TYPE_VIDEO,
    MEDIA_TYPE_AUDIO,
  ];
  const mediaDropdownItems = defaultMediaOptions.map((mediaOption) => ({
    name: tCms(mediaOption),
    value: mediaOption,
  }));

  return (
    <ModalInputWrapper
      label={tCms("mediaType")}
      description={tCms("mediaSelector-pleaseSelectType")}
    >
      <PbDropdown
        dropdownList={mediaDropdownItems}
        onChange={(e) => {
          props.onChange(e.target.value);
          setSelectedMediaType(e.target.value);
        }}
        value={selectedMediaType}
        itemNameKey={"name"}
        itemValueKey={"value"}
      ></PbDropdown>
    </ModalInputWrapper>
  );
}
