import CmsGeneralConfigFrame from "@/components/cms/cmsGeneralConfigFrame/cmsGeneralConfigFrame";
import CmsLinkChooserModal from "@/components/cms/cmsLinkChooser/cmsLinkChooserModal";
import { PbContentButtonProps } from "@/components/content/pbContentButton/pbContentButton";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import { updateAttributeAction } from "@/store/slices/cmsEdit/cmsEditSlice";
import { useAppSelector } from "@/store/store";
import clsx from "clsx";
import dynamic from "next/dynamic";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import CmsContentButtonGlobalStyle from "./cmsContentButtonGlobalStyle";

const Button = dynamic(() => import("@mui/material/Button"));
const CmsMaxLengthIndicator = dynamic(
  () => import("@/components/cms/cmsMaxLengthIndicator/cmsMaxLengthIndicator")
);

export interface CmsContentButtonProps extends PbContentButtonProps {}

/**
 * Component for updating/displaying (editmode/editview) values
 * from the CMS page.content array
 * Do not use this for other purposes
 */
const CmsContentButton = (props: CmsContentButtonProps) => {
  const tCms = useCmsTranslation();
  const editView = useAppSelector((state) => state.cmsGeneral.editView);
  const dispatch = useDispatch();

  const btnTextInputRef = useRef<HTMLInputElement>(null);

  const [linkChooserModal, setLinkChooserModal] = useState(false);

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updateAttributeAction({
        attributePath: `draftPage.content[${props.cmsPosition}].${props.cmsFieldText}`,
        value: e.target.value,
      })
    );
  };

  return (
    <>
      {editView && !props.disableEditView ? (
        <div className="cms-content-button">
          <div className={clsx("mui-button-wrapper", props.className)}>
            <CmsGeneralConfigFrame
              hideAddCEButton
              frameOnHover
              openConfigModal={() =>
                (props.url || !props.onClick) && setLinkChooserModal(true)
              }
            >
              <div className="pt-4">
                <Button
                  type="button"
                  className={clsx("mui-cms-button")}
                  tabIndex={-1}
                >
                  <div className="with-max-length-indicator">
                    <label
                      className="input-sizer"
                      data-value={props.text ? props.text : ""}
                    >
                      <input
                        className="cms-button-input"
                        type="text"
                        placeholder={tCms("buttonTextPlaceholder")}
                        defaultValue={props.text ? props.text : ""}
                        onChange={handleTextChange}
                        maxLength={250}
                        ref={btnTextInputRef}
                      />
                    </label>
                    <CmsMaxLengthIndicator
                      maxLength={250}
                      length={btnTextInputRef?.current?.value.length ?? 0}
                    />
                  </div>
                </Button>
              </div>
            </CmsGeneralConfigFrame>
            <CmsLinkChooserModal
              title={tCms("button-buttonConfig")}
              open={linkChooserModal}
              url={props.url ?? ""}
              rel={props.rel ?? ""}
              target={props.target ?? ""}
              hrefLang={props.hrefLang ?? ""}
              getParams={props.getParams ?? ""}
              anchorName={props.anchorName ?? ""}
              onCancel={() => setLinkChooserModal(false)}
              onConfirm={(result) => {
                dispatch(
                  updateAttributeAction({
                    attributePath: `draftPage.content[${props.cmsPosition}].${props.cmsFieldUrl}`,
                    value: result.url,
                  })
                );
                dispatch(
                  updateAttributeAction({
                    attributePath: `draftPage.content[${props.cmsPosition}].${props.cmsFieldRel}`,
                    value: result.rel,
                  })
                );
                dispatch(
                  updateAttributeAction({
                    attributePath: `draftPage.content[${props.cmsPosition}].${props.cmsFieldTarget}`,
                    value: result.target === "" ? "_self" : result.target,
                  })
                );
                dispatch(
                  updateAttributeAction({
                    attributePath: `draftPage.content[${props.cmsPosition}].${props.cmsFieldHrefLang}`,
                    value: result.hrefLang,
                  })
                );
                dispatch(
                  updateAttributeAction({
                    attributePath: `draftPage.content[${props.cmsPosition}].${props.cmsFieldGetParams}`,
                    value: result.getParams,
                  })
                );
                dispatch(
                  updateAttributeAction({
                    attributePath: `draftPage.content[${props.cmsPosition}].${props.cmsFieldAnchorName}`,
                    value: result.anchorName,
                  })
                );
                setLinkChooserModal(false);
              }}
            />
          </div>
        </div>
      ) : null}
      <CmsContentButtonGlobalStyle />
    </>
  );
};

export default CmsContentButton;
