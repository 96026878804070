export default function HtmlEditorModalGlobalStyle() {
  return (
    <style jsx global>{`
      .cms-html-editor-modal {
        .MuiOutlinedInput-input.MuiInputBase-inputMultiline {
          font-size: 16px;
          height: 50vh !important;
        }
      }
    `}</style>
  );
}
