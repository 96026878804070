import { valueFromStoreSetting } from "@/services/ceSettings/ceSettingsService";
import { StoreSetting } from "@/types/ceSettings/ceSettings";
import { STORE_BACKGROUND_IMAGE_CLASS_PREFIX } from "@/utils/constants";

export default function useStrBackgroundImage(
  backgroundImageStoreSetting?: StoreSetting
) {
  const backgroundImageId = backgroundImageStoreSetting
    ? valueFromStoreSetting(backgroundImageStoreSetting)
    : null;

  const backgroundImageClassName = backgroundImageId
    ? `${STORE_BACKGROUND_IMAGE_CLASS_PREFIX}${backgroundImageId}`
    : "";

  return { backgroundImageClassName };
}
