import PbButton from "@/components/input/pbButton/pbButton";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  OutlinedInput,
} from "@mui/material";
import { useRef } from "react";
import HtmlEditorModalGlobalStyle from "./htmlEditorModalGlobalStyle";

interface HtmlEditorModalProps {
  /**
   * Overwrites the richtexteditors value with the html editor value
   */
  onConfirm: (value: string) => void;
  /**
   * Saves the current html editor value to a state
   */
  setValue: (value: string) => void;
  /**
   * Overwrites the current html editor value with the current editor value
   */
  reset: () => void;
  /**
   * Toggles the html-editors visibility
   */
  show: boolean;
  /**
   * Setter for show
   */
  setShow: (show: boolean) => void;
  /**
   * The html text string
   */
  value: string;
  /**
   * Performs some line breaks and indentations to make the html string more readable
   */
  formatHtmlEditorValue: () => void;
  /**
   * Prompt component or text displayed above the editor input
   */
  promptComponent?: JSX.Element | string;
  /**
   * Text of the confirm button
   */
  confirmButtonText?: string;
  /**
   * Component or text displayed below the editor input
   */
  hintComponent?: JSX.Element | string;
}

/**
 * HtmlEditorModal
 */
export default function HtmlEditorModal(props: HtmlEditorModalProps) {
  const tCms = useCmsTranslation();
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const handleConfirm = () => {
    if (textareaRef.current) {
      props.onConfirm(textareaRef.current.value);
    }
    props.setShow(false);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    props.setValue(e.target.value);
  };

  const handleReset = () => {
    props.reset();
  };

  return (
    <>
      <Dialog
        open={props.show}
        fullWidth
        maxWidth="xl"
        className="cms-html-editor-modal cms-modal"
      >
        <DialogTitle className="cms-modal-title">
          {tCms("html-editor")}
        </DialogTitle>
        <IconButton
          className="modal-close"
          aria-label="close"
          onClick={() => props.setShow(false)}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          {props.promptComponent ?? null}
          <OutlinedInput
            multiline
            fullWidth
            inputRef={textareaRef}
            value={props.value}
            onChange={(e) => handleChange(e)}
            sx={{ textarea: { overflowY: "scroll !important" } }}
          />
          {props.hintComponent ?? null}
        </DialogContent>
        <DialogActions>
          <PbButton color="secondary" variant="outlined" onClick={handleReset}>
            {tCms("reset")}
          </PbButton>
          <PbButton
            color="secondary"
            variant="outlined"
            onClick={() => props.formatHtmlEditorValue()}
          >
            {tCms("format")}
          </PbButton>
          <PbButton color="primary" onClick={handleConfirm}>
            {props.confirmButtonText ?? tCms("save")}
          </PbButton>
        </DialogActions>
      </Dialog>
      <HtmlEditorModalGlobalStyle />
    </>
  );
}
