import { ConfigModalEntry } from "@/components/cms/cmsConfigModal/cmsConfigModal";
import GenericStoreConfigInputRenderer from "@/components/cms/cmsConfigModal/genericStoreConfigInputRenderer";
import CmsTabs from "@/components/cms/cmsTabs/cmsTabs";
import ModalInputWrapper from "@/components/util/modalInputWrapper";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import useConfigModalTabExtension from "@/hooks/useConfigModalTabExtension";
import { updateAttributeAction } from "@/store/slices/cmsGeneral/cmsGeneralSlice";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab } from "@mui/material";
import React, { useState } from "react";
import GenericConfigInputRenderer from "./genericConfigInputRenderer";

export interface GenericConfigTabsProps {
  configModalTabs: ConfigModalTab[];
  onChange: (attributeName: string, event: any) => void;
  children?: React.ReactNode;
}

export interface ConfigModalTab {
  label: string;
  tabName: string;
  value: string;
  configModalEntries: Array<ConfigModalEntry>;
}

export default function GenericConfigTabs(props: GenericConfigTabsProps) {
  const tCms = useCmsTranslation();
  const lastTabValue = useAppSelector<string>(
    (state) => state.cmsGeneral.configModal.lastTabValue
  );
  const dispatch = useAppDispatch();
  const [tabValue, setTabValue] = useState<string>(lastTabValue ?? "0");

  const {
    renderTabExtensionChildrenAtPosition,
    getAllTabExtensionChildren,
    getAllTabExtensionChildrenWithoutIndex,
  } = useConfigModalTabExtension(props.children);

  return (
    <CmsTabs>
      <TabContext value={tabValue}>
        <TabList
          className="tab-list"
          onChange={(event: any, newValue: any) => {
            setTabValue(newValue);
            dispatch(
              updateAttributeAction({
                attributePath: `configModal.lastTabValue`,
                value: newValue,
              })
            );
          }}
        >
          {props.configModalTabs.map((configModalTab) => {
            return (
              <Tab
                label={configModalTab.label}
                value={configModalTab.value}
                key={configModalTab.value}
              />
            );
          })}
        </TabList>
        {props.configModalTabs.map((configModalTab) => {
          return (
            <TabPanel
              value={configModalTab.value}
              key={configModalTab.value}
              className="cms-modal-input-wrapper-container"
            >
              {configModalTab.configModalEntries.map(
                (configEntry: ConfigModalEntry, index: number) => {
                  const label = tCms("configModal-" + configEntry.name);
                  const description = tCms(
                    "configModal-" + configEntry.name + "Description"
                  );

                  return (
                    <React.Fragment key={`input-wrapper-${index}`}>
                      {renderTabExtensionChildrenAtPosition(
                        configModalTab.tabName,
                        index,
                        false
                      )}
                      {configEntry.name.startsWith("cfgStr") ? (
                        <GenericStoreConfigInputRenderer
                          configEntry={configEntry}
                          index={index}
                          onChange={props.onChange}
                          label={label}
                          description={description}
                        />
                      ) : (
                        <ModalInputWrapper
                          label={label}
                          description={description}
                        >
                          <GenericConfigInputRenderer
                            configEntry={configEntry}
                            index={index}
                            onChange={props.onChange}
                          />
                        </ModalInputWrapper>
                      )}
                      {renderTabExtensionChildrenAtPosition(
                        configModalTab.tabName,
                        index,
                        true
                      )}
                      {index ===
                      configModalTab.configModalEntries.length - 1 ? (
                        <>
                          {getAllTabExtensionChildrenWithoutIndex(
                            configModalTab.tabName
                          ).map((element, tabExtensionIndex) => {
                            return (
                              <React.Fragment
                                key={`tab-extension-${tabExtensionIndex}`}
                              >
                                {element}
                              </React.Fragment>
                            );
                          })}
                        </>
                      ) : null}
                    </React.Fragment>
                  );
                }
              )}
              {configModalTab.configModalEntries.length === 0 ? (
                <>
                  {getAllTabExtensionChildren(configModalTab.tabName).map(
                    (element, index) => {
                      return (
                        <React.Fragment key={`tab-extension-${index}`}>
                          {element}
                        </React.Fragment>
                      );
                    }
                  )}
                </>
              ) : null}
            </TabPanel>
          );
        })}
      </TabContext>
    </CmsTabs>
  );
}
