import { ConfigModalEntry } from "@/components/cms/cmsConfigModal/cmsConfigModal";
import PbCheckBox from "@/components/input/pbCheckbox/pbCheckbox";
import PbDropdown, {
  PbDropdownItem,
} from "@/components/input/pbDropdown/pbDropdown";
import PbInput from "@/components/input/pbInput/pbInput";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import { SelectChangeEvent } from "@mui/material";
import AnchorConfigInput from "./customConfigInputElements/anchorConfigInput";

interface GenericConfigInputRendererProps {
  configEntry: ConfigModalEntry;
  index: number;
  onChange: (attributeName: string, event: any) => void;
}

export default function GenericConfigInputRenderer(
  props: GenericConfigInputRendererProps
) {
  const tCms = useCmsTranslation();

  const renderConfigInputElement = (
    configEntry: ConfigModalEntry,
    index: number
  ) => {
    switch (configEntry.attributes.type) {
      case "boolean":
        return (
          <PbCheckBox
            key={index}
            withoutColon
            value={configEntry.value as boolean}
            isSlider={true}
            onChange={() => {
              props.onChange(configEntry.name, !configEntry.value);
            }}
            fullHeight
          />
        );
      case "string":
        if (configEntry.name === "cfgAnchorName") {
          return (
            <AnchorConfigInput
              index={index}
              configEntry={configEntry}
              onChange={props.onChange}
            />
          );
        }
        return (
          <PbInput
            type="text"
            defaultValue={configEntry.value}
            fullWidth
            onChange={(e) => {
              props.onChange(configEntry.name, e.target.value);
            }}
          />
        );
      case "integer":
        return (
          <PbInput
            type="number"
            fullWidth
            defaultValue={configEntry.value || 0}
            onChange={(e) => {
              props.onChange(configEntry.name, e.target.value);
            }}
          />
        );
      case "enumeration":
        return (
          <PbDropdown
            dropdownList={configEntry.attributes.enum.map(
              (element: string): PbDropdownItem => ({
                value: element,
                name: tCms("configModal-" + element),
              })
            )}
            onChange={(event: SelectChangeEvent) => {
              props.onChange(configEntry.name, event.target.value);
            }}
            defaultValue={configEntry.value as string}
            name="cms-language-selector"
            itemNameKey={"name"}
            itemValueKey={"value"}
          />
        );
      case "relation":
        return <>TODO implement media manager selector</>;
    }
    return <></>;
  };

  return renderConfigInputElement(props.configEntry, props.index);
}
