import PbInput from "@/components/input/pbInput/pbInput";
import ModalInputWrapper from "@/components/util/modalInputWrapper";
import useCmsTranslation from "@/hooks/useCmsTranslation";

interface CmsMediaSelectorYouTubeProps {
  ytUrl: string;
  onChange: (onChangeYoutubeValues: OnChangeYoutubeValues) => void;
}

export interface OnChangeYoutubeValues {
  ytUrl: string;
}

export default function CmsMediaSelectorYouTube(
  props: CmsMediaSelectorYouTubeProps
) {
  const tCms = useCmsTranslation();
  return (
    <ModalInputWrapper
      label={tCms("youTube")}
      description={tCms("mediaSelector-pleaseSelectYoutubeUrl")}
    >
      <PbInput
        fullWidth
        onChange={(e) => {
          const onChangeYoutubeValues: OnChangeYoutubeValues = {
            ytUrl: e.target.value,
          };
          props.onChange && props.onChange(onChangeYoutubeValues);
        }}
        defaultValue={props.ytUrl}
      />
    </ModalInputWrapper>
  );
}
