import PbGenericModal from "@/components/genericModal/pbGenericModal";
import { PbIcon } from "@/components/pbIcon/PbIcon";
import { SvgIds } from "@/components/pbIcon/svg";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import { cmsAddContentElementThunk } from "@/store/slices/cmsEdit/cmsPageThunks";
import { hideAddContentModal } from "@/store/slices/cmsGeneral/cmsGeneralSlice";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { Container } from "react-bootstrap";
import CmsAddContentModalStyle from "./cmsAddContentModalStyle";

export interface CmsAddContentModalProps {}

export default function CmsAddContentModal(props: CmsAddContentModalProps) {
  const tCms = useCmsTranslation();
  const addContentModalOpen = useAppSelector(
    (state) => state.cmsGeneral.addContentModal.open
  );
  const availableElements = useAppSelector(
    (state) => state.cmsEdit.data?.availableElements
  );
  const dispatch = useAppDispatch();
  const position = useAppSelector(
    (state) => state.cmsGeneral.addContentModal.position
  );

  const handleAddElement = async (element: any) => {
    dispatch(cmsAddContentElementThunk(element, position ?? undefined));
    dispatch(hideAddContentModal());
  };

  const sortAddContentModalButtons = (a: any, b: any) => {
    const nameA = tCms("contentelement-" + a.uid).toLowerCase();
    const nameB = tCms("contentelement-" + b.uid).toLowerCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  };

  return (
    <>
      <PbGenericModal
        className="cms-modal cms-add-content-modal"
        open={addContentModalOpen}
        title={tCms("addNewElement")}
        muiDialogProps={{ fullWidth: true, maxWidth: "lg" }}
        onCancelClick={() => dispatch(hideAddContentModal())}
      >
        <Container fluid className="add-content-modal-wrapper">
          <div className="button-row">
            {availableElements &&
              [...availableElements]
                .sort(sortAddContentModalButtons)
                .map((element: any, index: number) => {
                  const svgId = (element.uid.replace(".", "-") +
                    "-allow-fill") as SvgIds;
                  return (
                    <div
                      key={svgId}
                      className={`button-col add-content-btn-container ${element.uid}`}
                    >
                      <button
                        onClick={() => handleAddElement(element)}
                        className={`add-content-element-button`}
                      >
                        <span className="button-svg">
                          <PbIcon name={svgId} width={50} height={50} />
                        </span>
                        <span className="button-title">
                          {tCms("contentelement-" + element.uid)}
                        </span>
                      </button>
                    </div>
                  );
                })}
            <div className="space-filler"></div>
          </div>
        </Container>
      </PbGenericModal>
      <CmsAddContentModalStyle />
    </>
  );
}
