import CmsConfigRenderer from "@/components/cms/cmsConfigModal/cmsConfigRenderer";
import PbButton from "@/components/input/pbButton/pbButton";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import useConfigModalCurrentElement from "@/hooks/useConfigModalCurrentElement";
import useContentElementStore from "@/hooks/useContentElementStore";
import { CFG_CONTENT_ELEMENT_SETTING_NAME } from "@/services/ceSettings/ceSettingsService";
import { updateAttributeAction } from "@/store/slices/cmsEdit/cmsEditSlice";
import {
  hideConfigModal,
  refreshRenderKey,
} from "@/store/slices/cmsGeneral/cmsGeneralSlice";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { StoreSetting } from "@/types/ceSettings/ceSettings";
import { deepImmutableCopy } from "@/utils/util";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useCallback, useEffect, useRef } from "react";

interface CmsConfigModalProps {}

export interface ConfigModalEntry {
  name: string;
  value: string | number | StoreSetting | boolean;
  attributes: any;
}

/**
 * The config modal is a configuration modal used to configure single instances 
 * of content elements. The UI within the config modal is rendered dynamically 
 * based on the attributes that each content element has.
 * 
 * Each attribute of a content element beginning with `cfg` relates to the 
 * config modal. Most attributes are rendered within the config modal as an 
 * input or component that enables the content manager to change its value in 
 * a way intended by the page builder.
 * 
 * @param {CmsConfigModalProps} props - The properties passed to the component.
 * @returns {JSX.Element} The rendered configuration modal.
 */
export default function CmsConfigModal(props: CmsConfigModalProps) {
  const tCms = useCmsTranslation();
  const dispatch = useAppDispatch();
  const { currentElement, attributeBasePath, parentCE } =
    useConfigModalCurrentElement();
  const open = useAppSelector((state) => state.cmsGeneral.configModal.open);
  const renderKey = useAppSelector((state) => state.cmsGeneral.renderKey);

  let currentOriginalElement = useRef<any>(null);

  const { setCurrentContentElementSetting, resetContentElementSetting } =
    useContentElementStore();

  const initConfigModal = useCallback(() => {
    currentOriginalElement.current = deepImmutableCopy(currentElement);
    setCurrentContentElementSetting(
      currentElement[CFG_CONTENT_ELEMENT_SETTING_NAME] as StoreSetting
    );
  }, [currentElement, attributeBasePath, setCurrentContentElementSetting]);

  useEffect(() => {
    if (open) {
      if (currentOriginalElement.current === null) {
        initConfigModal();
      }
    } else {
      currentOriginalElement.current = null;
    }
  }, [open, initConfigModal]);

  const handleChange = (attributeName: string, value: any) => {
    dispatch(
      updateAttributeAction({
        attributePath: `draftPage.${attributeBasePath}.${attributeName}`,
        value: value,
      })
    );
    switch (attributeName) {
      case CFG_CONTENT_ELEMENT_SETTING_NAME:
        setCurrentContentElementSetting(value);
        return;
      case "cfgStrBackground":
        resetContentElementSetting(
          "cfgStrBackgroundColor",
          currentElement,
          attributeBasePath
        );
        return;
      case "cfgStrBackgroundColor":
        resetContentElementSetting(
          "cfgStrBackground",
          currentElement,
          attributeBasePath
        );
        return;
      default:
        return;
    }
  };

  const configAttributes = (): ConfigModalEntry[] => {
    if (!currentElement) {
      return [];
    }
    return Object.entries(currentElement)
      .filter(([key, _]) => key.startsWith("cfg") && key !== "cfgInfo")
      .map(
        ([key, value]) =>
          ({
            name: key,
            value,
            attributes: currentElement.attributes[key],
          } as ConfigModalEntry)
      );
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const componentName =
    currentElement?.__component ?? currentElement?.uid ?? "";

  return (
    <Dialog
      className="cms-config-modal cms-modal with-tabs"
      open={open}
      maxWidth={"md"}
      fullWidth={true}
      fullScreen={fullScreen}
      PaperProps={{
        sx: { height: "100%" },
      }}
    >
      <DialogTitle className="cms-modal-title">
        {tCms("settings") + " - " + tCms("contentelement-" + componentName)}
      </DialogTitle>
      <IconButton
        className="modal-close"
        aria-label="close"
        onClick={() => {
          dispatch(hideConfigModal());
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        sx={{
          padding: 0,
        }}
        key={renderKey}
      >
        <CmsConfigRenderer
          attributes={configAttributes()}
          component={componentName}
          onChange={handleChange}
          currentElement={currentElement}
          parentCE={parentCE}
        ></CmsConfigRenderer>
      </DialogContent>
      <DialogActions>
        <PbButton
          color="secondary"
          variant="outlined"
          onClick={() => {
            dispatch(
              updateAttributeAction({
                attributePath: `draftPage.${attributeBasePath}`,
                value: currentOriginalElement.current,
              })
            );
            dispatch(refreshRenderKey());
          }}
        >
          {tCms("reset")}
        </PbButton>
        <PbButton
          color="primary"
          onClick={() => {
            dispatch(hideConfigModal());
          }}
        >
          {tCms("confirm")}
        </PbButton>
      </DialogActions>
    </Dialog>
  );
}
