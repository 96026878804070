import { determineStoreValueId } from "@/services/ceSettings/ceSettingsService";
import { StoreSetting } from "@/types/ceSettings/ceSettings";
import { STORE_BUTTON_CLASS_PREFIX } from "@/utils/constants";

export default function useStrButton(
  buttonStoreSetting?: StoreSetting,
  ceSettings?: StoreSetting
) {
  const buttonId = determineStoreValueId(buttonStoreSetting, ceSettings);

  const buttonClassName = buttonId
    ? `${STORE_BUTTON_CLASS_PREFIX}${buttonId}`
    : "default-btn";

  return { buttonClassName };
}
